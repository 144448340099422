import Vue from "vue";
import MastheadDesktop from "./vue-components/desktop/MastheadDesktop";
import MastheadMobile from "./vue-components/mobile/MastheadMobile";

import store, { actions } from "./store";

export default (config) =>
  config.map(({ targetSelector: el, navData, searchData }) => {
    actions.setNavData(navData);
    actions.mergeSiteSearchData(searchData);

    const app = new Vue({
      el,
      name: "MastheadContainer",
      data() {
        return store;
      },
      render(h) {
        const component = this.isDesktop ? MastheadDesktop : MastheadMobile;

        return h(component);
      },
    });

    return {
      config,
      app,
    };
  });
