<template>
  <header class="m-masthead bg-grey" role="banner" data-masthead>
    <div class="wrapper">
      <div class="p-top relative">
        <div class="g align-apart align-v-center">
          <div class="g__col">
            <masthead-brand />
          </div>
        </div>
        <div class="mg-top">
          <masthead-desktop-primary-nav />
          <!-- {% render '@masthead-nav' %} -->
        </div>
        <nav class="m-masthead__nav">
          <ul class="m-masthead__nav-list list--inline">
            <li v-for="(auxItem, idx) in state.navData.AuxillaryItems" :key="auxItem.Url">
              <a
                :class="{
                  'm-masthead__nav-item--has-divider': hasDivider(idx),
                }"
                class="m-masthead__nav-item"
                :href="auxItem.Url"
              >
                <span
                  :class="{
                    'm-masthead__nav-item__divider': hasDivider(idx),
                  }"
                  v-html="auxItem.Title"
                />
              </a>
            </li>
            <li>
              <button
                ref="searchButton"
                :aria-expanded="state.isSearchExpanded ? 'true' : 'false'"
                :aria-controls="id('search')"
                class="m-masthead__nav-item"
                type="button"
                @click="toggleSearch"
                @focus="
                  bindTabKey({
                    condition: 'isSearchExpanded',
                    from: 'searchButton',
                  })
                "
                @blur="unbindTabKey"
              >
                <span class="sr-only"> {{ state.isSearchExpanded ? `Hide` : `Expand` }} </span>
                <span class="mg-right--half">Search</span>
                <icon :name="state.isSearchExpanded ? `close` : `search`" title="Search" />
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <portal selector="#mobile-masthead-nav-search" aria-live="polite">
      <div class="shu-design-system-v1-0">
        <masthead-search
          v-if="state.isSearchExpanded"
          :id="id('search')"
          @hide="handleSearchClose"
        />
      </div>
    </portal>
  </header>
</template>
<script>
import { Portal } from "@linusborg/vue-simple-portal";
import MastheadBase from "@/components/molecules/masthead/vue-components/base/MastheadBase";
import Icon from "@/components/atoms/icon/Icon";

import MastheadBrand from "@/components/molecules/masthead/vue-components/shared/MastheadBrand";
import MastheadDesktopPrimaryNav from "@/components/molecules/masthead/vue-components/desktop/MastheadDesktopPrimaryNav";
import MastheadSearch from "@/components/molecules/masthead/vue-components/shared/MastheadSearch";

export default {
  name: "MastheadDesktop",
  components: {
    Icon,
    MastheadBrand,
    MastheadDesktopPrimaryNav,
    MastheadSearch,
    Portal,
  },
  extends: MastheadBase,
  beforeDestroy() {
    this.toggleMenu(false);
    this.toggleSearch(false);
  },
  methods: {
    hasDivider: (idx) => idx > 0,
    handleSearchClose() {
      this.toggleSearch();
      this.$refs.searchButton.focus();
    },
  },
};
</script>
