document.addEventListener("DOMContentLoaded", () => {
  const intersectionOptions = {
    root: null,
    rootMargin: "-50% 0px -50% 0px",
    threshold: 0,
  };

  let currentActiveNavLink = null; // Track the currently active navigation link

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      const id = entry.target.getAttribute("id");
      const navLink = document.querySelector(`nav li a[href="#${id}"]`);

      if (entry.isIntersecting) {
        if (currentActiveNavLink !== null && currentActiveNavLink !== navLink) {
          currentActiveNavLink.classList.remove("is--active");
        }
        navLink.classList.add("is--active");
        currentActiveNavLink = navLink; // Update the currently active navigation link
      } else if (currentActiveNavLink === navLink) {
        navLink.classList.remove("is--active");
        currentActiveNavLink = null; // Reset the currently active navigation link
      }
    });
  };

  const observer = new IntersectionObserver(handleIntersection, intersectionOptions);

  const observeSections = () => {
    document.querySelectorAll("[data-section='split'][id]").forEach((section) => {
      observer.observe(section);
    });
  };

  observeSections();
});
