import Vue from "vue";

import NewMastheadNav from "./vue-components/NewMastheadNav.vue";

export default (config) =>
  config.map(({ targetSelector: el,}) => {
    const app = new Vue({
      el,
      name: "newMastheadNav",
      render(h) {
        const component = NewMastheadNav;
        return h(component);
      },
    });

    return {
      config,
      app,
    };
  });
