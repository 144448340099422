// dummy data
export const example = [
    {
        "Title": "Study here",
        "Url": "https://www.google.com/",
        "FirstFeaturedItem": [
            {
                "Title": "Why choose us?",
                "Url": "#study-why-choose-us",
                "Image": {
                    "alt": "Study here image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Study here",
                        "title": "Study here"
                    },
                    "src": "/dist/img/something.svg"
                }
            }
        ],
        "SecondFeaturedItem": [
            {
                "Title": "Explore Sheffield",
                "Url": "#study-explore-sheffield",
                "Image": {
                    "alt": "Explore Sheffield image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Explore Sheffield",
                        "title": "Explore Sheffield"
                    },
                    "src": "/dist/img/some.svg"
                }
            }
        ],
        "NonFeaturedItems": [
            {
                "Title": "Student life",
                "Url": "#study-student-life"
            },
            {
                "Title": "Guaranteed accomodation",
                "Url": "#study-guaranteed-accomodation"
            },
            {
                "Title": "Fees and funding",
                "Url": "#study-fees-and-funding"
            },
            {
                "Title": "How to apply",
                "Url": "#study-how-to-apply"
            }
        ]
    },
    {
        "Title": "Research",
        "Url": "#Research",
        "FirstFeaturedItem": [
            {
                "Title": "Research in action",
                "Url": "#research-research-in-action",
                "Image": {
                    "alt": "Research in action image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Research in action",
                        "title": "Research in action"
                    },
                    "src": "/dist/img/something.svg"
                }
            }
        ],
        "SecondFeaturedItem": [
            {
                "Title": "Excellence and integrity",
                "Url": "#excellence-and-integrity",
                "Image": {
                    "alt": "Excellence and integrity image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Excellence and integrity",
                        "title": "Excellence and integrity"
                    },
                    "src": "/dist/img/some.svg"
                }
            }
        ],
        "NonFeaturedItems": [
            {
                "Title": "Research areas",
                "Url": "#research-research-areas"
            },
            {
                "Title": "Research degrees",
                "Url": "#research-degrees"
            },
            {
                "Title": "Events",
                "Url": "#events"
            },
            {
                "Title": "Outputs and data",
                "Url": "#research-research-data-and-outputs"
            },
            {
                "Title": "People",
                "Url": "#research-people"
            },
            {
                "Title": "Contact us",
                "Url": "#research-contact-us"
            }
        ]
    },
    {
        "Title": "Alumni",
        "Url": "#Alumni",
        "FirstFeaturedItem": [
            {
                "Title": "Your career",
                "Url": "#careers-support",
                "Image": {
                    "alt": "Your career image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Your career",
                        "title": "Your career"
                    },
                    "src": "/dist/img/something.svg"
                }
            }
        ],
        "SecondFeaturedItem": [
            {
                "Title": "Discounts and facilities",
                "Url": "#discounts-and-facilities",
                "Image": {
                    "alt": "Discounts and facilities image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Discounts and facilities",
                        "title": "Discounts and facilities"
                    },
                    "src": "/dist/img/some.svg"
                }
            }
        ],
        "NonFeaturedItems": [
            {
                "Title": "Your organisation",
                "Url": "#supporting-your-organisation"
            },
            {
                "Title": "Manage your graduate profile",
                "Url": "#your-graduate-profile"
            },
            {
                "Title": "How do I?",
                "Url": "#how-do-i"
            },
            {
                "Title": "Alumni news",
                "Url": "#news"
            },
            {
                "Title": "Giving",
                "Url": "#giving"
            },
            {
                "Title": "Contact us",
                "Url": "#alumni-contact-us"
            }
        ]
    },
    {
        "Title": "Business",
        "Url": "#Business",
        "FirstFeaturedItem": [
            {
                "Title": "Start your business",
                "Url": "#start-your-business",
                "Image": {
                    "alt": "Start your business image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Start your business",
                        "title": "Start your business"
                    },
                    "src": "/dist/img/something.svg"
                }
            }
        ],
        "SecondFeaturedItem": [
            {
                "Title": "Grow your business",
                "Url": "#grow-your-business",
                "Image": {
                    "alt": "Grow your business image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Grow your business",
                        "title": "Grow your business"
                    },
                    "src": "/dist/img/some.svg"
                }
            }
        ],
        "NonFeaturedItems": [
            {
                "Title": "Large business support",
                "Url": "#support-for-large-businesses"
            },
            {
                "Title": "Apprenticeships",
                "Url": "#apprenticeships"
            },
            {
                "Title": "Hire a space",
                "Url": "#hire-a-space"
            },
            {
                "Title": "Upcoming evetns",
                "Url": "#events"
            },
        ]
    },
    {
        "Title": "About us",
        "Url": "#About-us",
        "FirstFeaturedItem": [
            {
                "Title": "Our people",
                "Url": "#our-people",
                "Image": {
                    "alt": "Our people image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "Our people",
                        "title": "Our people"
                    },
                    "src": "/dist/img/something.svg"
                }
            }
        ],
        "SecondFeaturedItem": [
            {
                "Title": "University structure",
                "Url": "#academic-departments",
                "Image": {
                    "alt": "University structure image",
                    "height": 200,
                    "width": 140,
                    "link": {
                        "text": "University structure",
                        "title": "University structure"
                    },
                    "src": "/dist/img/some.svg"
                }
            }
        ],
        "NonFeaturedItems": [
            {
                "Title": "Who we are",
                "Url": "#who-we-are"
            },
            {
                "Title": "Governance",
                "Url": "#governance"
            },
            {
                "Title": "Our values",
                "Url": "#our-values"
            },
            {
                "Title": "Our role in the region",
                "Url": "#our-role-in-the-region"
            },
            {
                "Title": "Strategy",
                "Url": "#strategy"
            },
        ]
    },
    
]