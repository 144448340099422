<!-- desktop nav - from medium screen 720px above -->
<template>
  <nav class="container" aria-label="Primary nav" data-new-masthead-nav>
    <div class="masthead-nav-wrapper">
      <ul class="masthead-nav-links">
        <li
          v-for="(item, index) in sampleData"
          :key="item.Url"
          class="masthead-nav-links__item"
          @pointerover="showMenu(index)"
          @pointerleave="setTimer"
        >
          <a
            tabindex="0"
            :href="item.Url"
            :aria-labelledby="item.Title"
            class="masthead-nav-links__item-heading"
            @pointerover="showMenu(index)"
            @focusin="showMenu(index)"
            @focusout="setTimer"
            @keydown.esc="hideMenu"
          >
            {{ item.Title }}
            <svg
              class="chevron-label white-arrow"
              width="30"
              height="30"
              viewBox="0 0 16 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.84959 0L0 2.84959L7.97884 10.8284L10.8284 7.97884L2.84959 0Z"
                fill="white"
              />
              <path
                d="M5.12915 8L7.97874 10.8496L15.9576 2.87074L13.108 0.0211549L5.12915 8Z"
                fill="white"
              />
            </svg>
            <svg
              class="chevron-label regular-arrow"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.84959 6.3999L4 9.24949L11.9788 17.2283L14.8284 14.3787L6.84959 6.3999Z"
                fill="#333333"
              />
              <path
                d="M9.12915 14.3999L11.9787 17.2495L19.9576 9.27064L17.108 6.42106L9.12915 14.3999Z"
                fill="#333333"
              />
            </svg>
          </a>
          <!-- dropdown menu -->

          <div
            v-if="menuOpen === index"
            class="dropdown-menu"
            @pointerenter="showMenu(index)"
            @pointerleave="hideMenu"
          >
            <ul class="drop-menu-item menu-position">
              <li class="column">
                <div class="drop-menu-item__img-block">
                  <img src="" height="200" width="140" />
                  <hr />
                  <a href="https://www.google.com/">{{
                    item.FirstFeaturedItem[0].Title
                  }}</a>
                </div>
              </li>
              <li class="column">
                <div class="drop-menu-item__img-block">
                  <img src="" height="200" width="140" />
                  <hr />
                  <a href="https://www.google.com/">{{
                    item.SecondFeaturedItem[0].Title
                  }}</a>
                </div>
              </li>
              <div class="column drop-menu-item__list">
                <li
                  v-for="menuItem in item.NonFeaturedItems"
                  :key="menuItem.Url"
                >
                  <a :href="menuItem.Url">{{ menuItem.Title }}</a>
                </li>
              </div>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>
    
  <script>
// import InLineSvg from "vue-inline-svg";
import { example } from "./data/exampledata";

export default {
  // components: { InLineSvg },
  data() {
    return {
      menuOpen: null,
      currentTimer: null,
      // this to be replaced with fetched json from backend
      sampleData: example,
    };
  },
  methods: {
    // passing in index of the menu item so it will show the correct menu items only
    showMenu(index) {
      this.menuOpen = index;
      this.clearTimer();
    },
    hideMenu() {
      this.menuOpen = null;
    },
    // the timer is to ensure the user can tab through the sub menu
    setTimer() {
      this.currentTimer = setTimeout(() => {
        this.hideMenu();
      }, 5000);
    },
    clearTimer() {
      // reset current Timer
      if (this.currentTimer) {
        clearTimeout(this.currentTimer);
        this.currentTimer = null;
      }
    },
  },
};
</script>

<style lang="scss">
.masthead-nav-wrapper {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: block;
    text-decoration: none;
    transition: all 0.3s ease;
    padding: 0.5rem 1.5rem !important;
    color: #333 !important;
    text-decoration: none !important;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline !important;
    }
  }

  ul {
    list-style: none;
  }
}

.white-arrow {
  display: none;
}

.regular-arrow {
  display: inline;
}

.masthead-nav-links {
  position: relative;
  width: 100%;
  display: flex;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;

  > li {
    border: 1px solid hsla(0, 0%, 43.9%, 0.25);
    display: flex;
    position: static;
    padding: 0 !important;
    margin: 0 !important;
    :focus-visible {
      outline: none;
      border: 4px solid #ba0046;
    }

    > a {
      background: #f7f7f7;
      display: flex;
      font-weight: 700 !important;
      justify-content: space-between;
      &:hover,
      &:focus,
      &:active {
        background: #ba0046;
        color: #f7f7f7 !important;
        text-decoration: underline !important;
      }
      &:hover .white-arrow,
      &:focus .white-arrow,
      &:active .white-arrow {
        display: inline;
        padding: 0.5rem 0.5rem;
      }
      &:hover .regular-arrow,
      &:focus .regular-arrow,
      &:active .regular-arrow {
        display: none;
      }
    }
  }

  &__item {
    display: flex;
    a {
      padding-right: 0.2rem;
    }
    svg {
      padding: 0.2rem 0.3rem;
    }
  }
}

.chevron-label {
  float: right;
  padding-bottom: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: #d9d9d9;
  display: block;
  z-index: 1;
  width: auto;
  height: auto;

  @media only screen and (min-width: 960px) {
    width: 911px;
    height: 439 px;
  }
}

.column {
  width: 240px;
  height: auto;
}
.drop-menu-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  &__img-block {
    display: inline-block;
    justify-content: center;
    margin-top: 3rem;
    width: 240px;
    height: 305px;
    background-color: #f7f7f7;

    hr {
      width: 140px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      margin: 1.5rem auto;
    }

    a {
      padding: 0;
      text-align: center;
      font-weight: 700;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__list {
    margin-top: 2rem;
    border-left: 2px solid #000000;

    margin-left: 1rem;
    @media only screen and (min-width: 960px) {
      margin-left: 0;
    }

    a {
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }
}
</style>