<template>
  <div class="p-top p-bottom relative">
    <div class="g align-apart align-v-center">
      <div class="g__col">
        <masthead-brand />
      </div>
      <div class="g__col">
        <div class="m-masthead__nav-toggles u-text-color-black">
          <button
            ref="searchButton"
            :aria-expanded="state.isSearchExpanded ? 'true' : 'false'"
            :aria-controls="id('search')"
            class="m-masthead__nav-toggle"
            type="button"
            @click="onToggleSearch"
            @focus="
              bindTabKey({
                condition: 'isSearchExpanded',
                from: 'searchButton',
              })
            "
            @blur="unbindTabKey"
          >
            <span class="sr-only"> {{ state.isSearchExpanded ? `Hide` : `Expand` }} Search </span>
            <icon :name="state.isSearchExpanded ? `close` : `search`" title="Search" />
          </button>
          <button
            ref="menuButton"
            :aria-expanded="state.isMenuExpanded ? 'true' : 'false'"
            :aria-controls="id('nav')"
            class="m-masthead__nav-toggle"
            type="button"
            @click="toggleMenu"
            @focus="
              (e) =>
                bindTabKey({
                  condition: 'isMenuExpanded',
                  from: 'menuButton',
                })
            "
            @blur="unbindTabKey"
          >
            <span class="sr-only"> {{ state.isMenuExpanded ? `Hide` : `Expand` }} Menu </span>
            <icon :name="state.isMenuExpanded ? `close` : `burger`" title="Menu" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { willAcceptFocus } from "@/js/helpers/focus-helper";

import Icon from "@/components/atoms/icon/Icon";

import { bus } from "@/components/molecules/masthead/bus";

import MastheadBrand from "@/components/molecules/masthead/vue-components/shared/MastheadBrand";
import MastheadBase from "@/components/molecules/masthead/vue-components/base/MastheadBase";

export default {
  components: {
    Icon,
    MastheadBrand,
  },
  extends: MastheadBase,
  computed: {
    conditionalFixedStyle() {
      return {
        position: this.state.isMenuExpanded ? "fixed" : "relative",
        inset: "0 0 auto 0",
      };
    },
  },
  created() {
    bus.$on("focussearchbutton", this.focusSearchButton);
  },
  beforeDestroy() {
    bus.$off("focussearchbutton", this.focusSearchButton);
  },
  methods: {
    focusSearchButton() {
      this.$refs.searchButton.focus();
    },
    onToggleSearch() {
      this.toggleSearch();
    },
    acceptFocus(opts) {
      willAcceptFocus(opts, {
        trigger: "mobileMenu",
        target: this.$refs.menuButton,
      });
      willAcceptFocus(opts, {
        trigger: "mobileSearch",
        target: this.$refs.searchButton,
      });
    },
  },
};
</script>
