import { Splide } from "@splidejs/splide";
import { Video } from "@splidejs/splide-extension-video";

document.addEventListener("DOMContentLoaded", function () {
  const mediaGalleryElement = document.querySelector("[data-media-gallery]");

  if (mediaGalleryElement) {
    const mainElement = mediaGalleryElement.querySelector("#main-carousel");
    const thumbnailsElement = mediaGalleryElement.querySelector("#thumbnail-carousel");

    if (mainElement && thumbnailsElement) {
      const main = new Splide(mainElement, {
        type: "slide",
        rewind: true,
        cover: true,
        pagination: false,
        arrows: true,
        video: {
          mute: true,
        },
      });

      const thumbnails = new Splide(thumbnailsElement, {
        arrows: false,
        fixedWidth: 300,
        fixedHeight: 200,
        rewind: true,
        pagination: false,
        isNavigation: true,
        breakpoints: {
          600: {
            fixedWidth: 60,
            fixedHeight: 44,
          },
        },
      });

      main.sync(thumbnails);
      main.mount({ Video });
      thumbnails.mount();
    }
  }
});
